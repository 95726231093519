import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Label.module.scss";

const Label = ({ className, children, title }) => (
  <label className={cx(styles.root, className)}>
    <span className={styles.title}>{title}</span>
    <span className={styles.input}>{children}</span>
  </label>
);

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Label;
