import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Alert.module.scss";

const Alert = ({ className, type, children }) => {
  return (
    <div
      className={cx(
        styles.root,
        {
          [styles.error]: type === "error",
          [styles.success]: type === "success",
        },
        className
      )}
    >
      {children}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(["error", "success"]),
  children: PropTypes.node.isRequired,
};

export default Alert;
