import PropTypes from "prop-types";
import React from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { EMAIL_ADDRESS, PHONE_NUMBER } from "../../constants";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import Alert from "../common/Alert";
import Button from "../common/Button";
import Label from "../common/Label";
import Container from "../common/Container";
import styles from "./Contact.module.scss";

const validationSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email().required(),
  tel: yup.string(),
  message: yup.string().required(),
});

class Contact extends React.Component {
  handleSubmit = async (values, { setSubmitting, setStatus, resetForm }) => {
    setStatus({ formError: null, formSuccess: null });
    try {
      const res = await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify(values),
      });
      setSubmitting(false);
      const data = await res.json();
      if (data && data.success) {
        resetForm();
        setStatus({ formSuccess: true });
      } else {
        setStatus({
          formError:
            "Something went wrong, please contact us using the direct email address or phone number on the page.",
        });
      }
    } catch (err) {
      setSubmitting(false);
      setStatus({ formError: err.message });
    }
  };

  render() {
    const { location, siteTitle } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Contact" keywords={["Contact"]} />
        <Hero title="Contact Us" />
        <Container>
          <div className={styles.row}>
            <Formik
              initialValues={{ name: "", email: "", tel: "", message: "" }}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ isSubmitting, isValid, status }) => (
                <Form className={styles.form} noValidate>
                  {status && status.formError && (
                    <Alert type="error">{status.formError}</Alert>
                  )}
                  {status && status.formSuccess && (
                    <Alert type="success">
                      Message sent! We'll get in touch soon.
                    </Alert>
                  )}
                  <Label title="Name">
                    <Field type="text" name="name" />
                  </Label>
                  <Label title="Email Address">
                    <Field type="email" name="email" required />
                  </Label>
                  <Label title="Phone Number">
                    <Field type="tel" name="tel" />
                  </Label>
                  <Label title="Message">
                    <Field
                      component="textarea"
                      name="message"
                      rows={6}
                      required
                    />
                  </Label>
                  <Button
                    className="mt3"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Send message
                  </Button>
                </Form>
              )}
            </Formik>
            <div className={styles.sidebar}>
              <strong>Call us anytime!</strong>
              <h2 className={styles.heading}>Address</h2>
              <address>
                <strong>O'Brien Tree Care</strong>
                <br />
                PO Box 18042
                <br />
                Golden, CO 80402
              </address>
              <h2 className={styles.heading}>Telephone</h2>
              <a href={`tel:+1${PHONE_NUMBER.replace(/-/g, "")}`}>
                {PHONE_NUMBER}
              </a>
              <h2 className={styles.heading}>Email</h2>
              <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
            </div>
          </div>
        </Container>
      </Layout>
    );
  }
}

Contact.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
};

export default Contact;
