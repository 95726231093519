import React from "react";
import { graphql } from "gatsby";
import Contact from "../components/Contact";

class ContactPage extends React.Component {
  render() {
    const { location, data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return <Contact location={location} siteTitle={siteTitle} />;
  }
}

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
